import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Divider
} from "@mui/material";
import moment from "moment";

// Importa tus funciones de Firestore
import {
  GuardarViajeGPS,
  CrearViajeEnCurso,
  ActualizarViajeEnCurso,
  // Aquí o en otro archivo define/usa consultarAutenticacion
} from "../../APIS/apiProveedor";

const Conductor = () => {
  const containerRef = useRef(null);

  // Estado para el ID del viaje
  const [idViaje, setIdViaje] = useState("");

  // Estados para fecha/hora de inicio y fin
  const [fechaInicio, setFechaInicio] = useState(null);
  const [horaInicio, setHoraInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [horaFin, setHoraFin] = useState(null);

  // Estado para almacenar las coordenadas GPS
  const [gps, setGps] = useState([]);

  // Guardamos el watchId para luego detener el tracking
  const [watchId, setWatchId] = useState(null);

  // Datos del usuario autenticado
  const [usuario, setUsuario] = useState({
    uid: "",
    nombre: "",
    apellido: "",
    rol: "",
  });

  // (Opcional) Estado para manejar mensajes de error
  const [errorMessage, setErrorMessage] = useState("");

  // Ejemplo de función para obtener datos de usuario; ajusta a tu caso real
  const consultarAutenticacion = async () => {
    // Podrías usar un context, leer localStorage,
    // o tu propia API para saber quién está logueado.
    // Como ejemplo, retornamos un mock:
    return {
      uid: "12345",
      nombre: "Juan",
      apellido: "Pérez",
      rol: "conductor",
    };
  };

  useEffect(() => {
    const obtenerDatosUsuario = async () => {
      try {
        const usuarioActual = await consultarAutenticacion();
        if (usuarioActual) {
          setUsuario(usuarioActual);
        } else {
          console.log("No se ha iniciado sesión o no hay datos de usuario.");
        }
      } catch (error) {
        console.error("Error al obtener datos del usuario:", error);
      }
    };
    obtenerDatosUsuario();
  }, []);

  // Cleanup del watchPosition si el usuario cierra o navega:
  useEffect(() => {
    return () => {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [watchId]);

  const startTracking = async () => {
    setErrorMessage(""); // Limpiamos error previo
    if (!idViaje) {
      alert("Por favor ingresa un ID de viaje antes de iniciar.");
      return;
    }

    const confirmar = window.confirm("¿Estás seguro de iniciar el viaje?");
    if (!confirmar) return;

    const now = new Date();
    setFechaInicio(now);
    setHoraInicio(now);

    // Crea el documento de viaje en Firestore
    await crearDocumentoInicialFirestore(now);

    // Verificamos si el navegador soporta la geolocalización
    if (!navigator.geolocation) {
      setErrorMessage("La geolocalización no está disponible en este navegador.");
      return;
    }

    // Iniciamos watchPosition
    const id = navigator.geolocation.watchPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const newLocation = {
          latitude,
          longitude,
          timestamp: new Date(),
        };

        setGps((prevGps) => [...prevGps, newLocation]);
        // Guardar la ubicación actual en Firestore
        try {
          await ActualizarViajeEnCurso(idViaje, newLocation);
        } catch (err) {
          console.error("Error al actualizar viaje:", err);
        }
      },
      (error) => {
        console.error("Error en watchPosition:", error);
        setErrorMessage("No se pudo obtener la ubicación. Revisa permisos y GPS.");
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 1000,
      }
    );

    setWatchId(id);
  };

  const stopTracking = async () => {
    if (!fechaInicio) {
      alert("No hay un viaje en curso.");
      return;
    }

    const confirmar = window.confirm("¿Estás seguro de finalizar este viaje?");
    if (!confirmar) return;

    if (watchId !== null) {
      navigator.geolocation.clearWatch(watchId);
      setWatchId(null);
    }

    const now = new Date();
    setFechaFin(now);
    setHoraFin(now);
    HandleSave(now);
  };

  const HandleSave = async (fin) => {
    try {
      if (fechaInicio && horaInicio && gps.length > 0) {
        const fechaInicioString = moment(fechaInicio).format("YYYY-MM-DD");
        const horaInicioString = moment(horaInicio).format("HH:mm:ss");

        const fechaFinUse = fin || fechaFin;
        const horaFinUse = fin || horaFin;

        const fechaFinString = fechaFinUse
          ? moment(fechaFinUse).format("YYYY-MM-DD")
          : null;
        const horaFinString = horaFinUse
          ? moment(horaFinUse).format("HH:mm:ss")
          : null;

        const datosViaje = {
          fechaInicio: fechaInicioString,
          horaInicio: horaInicioString,
          fechaFin: fechaFinString,
          horaFin: horaFinString,
          gps,
          usuario: usuario.nombre,
        };

        await GuardarViajeGPS(idViaje, datosViaje);
        alert("¡Viaje finalizado y guardado con éxito!");
        resetForm();
      } else {
        console.log("No se puede guardar. Asegúrate de iniciar el viaje primero.");
      }
    } catch (error) {
      console.error("Error al guardar el viaje:", error);
      setErrorMessage("Ocurrió un error al guardar el viaje.");
    }
  };

  const crearDocumentoInicialFirestore = async (now) => {
    const fechaInicioString = moment(now).format("YYYY-MM-DD");
    const horaInicioString = moment(now).format("HH:mm:ss");

    const datosIniciales = {
      fechaInicio: fechaInicioString,
      horaInicio: horaInicioString,
      fechaFin: null,
      horaFin: null,
      gps: [],
      usuario: usuario.nombre,
    };

    try {
      await CrearViajeEnCurso(idViaje, datosIniciales);
    } catch (err) {
      console.error("Error al crear documento inicial:", err);
    }
  };

  const resetForm = () => {
    setIdViaje("");
    setFechaInicio(null);
    setHoraInicio(null);
    setFechaFin(null);
    setHoraFin(null);
    setGps([]);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 2
      }}
    >
      <Paper
        elevation={10}
        sx={{
          width: "100%",
          maxWidth: 400,
          textAlign: "center",
          p: 2
        }}
      >
        <Typography variant="h6" mb={1}>
          Conductor - Inicio de Viaje
        </Typography>

        {errorMessage && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}

        {/* ID Viaje */}
        <TextField
          label="ID del Viaje"
          variant="outlined"
          fullWidth
          margin="normal"
          value={idViaje}
          onChange={(e) => setIdViaje(e.target.value)}
          disabled={Boolean(fechaInicio)}
        />

        {/* Botones Iniciar / Detener */}
        <Box mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={startTracking}
            disabled={Boolean(fechaInicio)} // Si hay fechaInicio => ya está en curso
            fullWidth
            sx={{ mb: 1 }}
          >
            Iniciar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={stopTracking}
            fullWidth
            disabled={!fechaInicio} // No se puede detener si no hay viaje iniciado
          >
            Detener
          </Button>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Info de GPS */}
        <Typography variant="body1">
          <strong>Estado GPS:</strong>
        </Typography>
        <Typography variant="body2">
          Ubicaciones registradas: {gps.length}
        </Typography>

        {gps.length > 0 && (
          <>
            <Typography variant="body2">
              Últ. lat: {gps[gps.length - 1].latitude.toFixed(5)}
            </Typography>
            <Typography variant="body2">
              Últ. lng: {gps[gps.length - 1].longitude.toFixed(5)}
            </Typography>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default Conductor;
