import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, Polyline, LoadScriptNext, Marker } from "@react-google-maps/api";
import { grey } from "@mui/material/colors";
import { Box, Typography, Slide, Grid, Card, Paper, AppBar, Container, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ObtenerUnViajeGPS } from "../../APIS/api";
import Logo from "../Imagenes/logo_morado_texto_al_lado.png";

const containerStyle = {
  width: "100%",
  height: "90vh",
};

const HoverableCard = styled(Card)(({ theme, isSelected }) => ({
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  width: "auto",
  margin: 10,
  color: theme.palette.text.primary,
  backgroundColor: isSelected ? "#333" : grey[200],
  transition: "background-color 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "#1976d2",
    color: "#fff",
    cursor: "pointer",
  },
  ...(isSelected && {
    backgroundColor: "#1976d2",
    color: "#fff",
  }),
}));

export default function SeguimientoUnServicio() {
  const { viajeId } = useParams();
  const [viaje, setViaje] = useState(null);
  const [polylinePath, setPolylinePath] = useState([]);
  const [center, setCenter] = useState({ lat: -33.38653483189307, lng: -70.79489558751244 });
  const [zoom, setZoom] = useState(16);
  const [isSectionVisible] = useState(true);

  useEffect(() => {
    if (!viajeId) return;
    const unsubscribe = ObtenerUnViajeGPS(viajeId, (data) => {
      setViaje(data);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [viajeId]);

  useEffect(() => {
  if (viaje) {
    console.log("Datos GPS del viaje:", viaje?.Datos?.gps);
    const gpsArray = viaje?.Datos?.gps || [];
    const reversed = gpsArray.slice().reverse();
    const path = reversed.map((p) => ({
      lat: p.latitude,
      lng: p.longitude,
    }));

    console.log("Path generado:", path);
    setPolylinePath(path);

    if (path.length > 0) {
      setCenter(path[0]);
      setZoom(16);
    }
  } else {
    setPolylinePath([]);
  }
}, [viaje]);


  if (!viaje) {
    return <Typography sx={{ p: 2 }}>Cargando viaje...</Typography>;
  }

  return (
    <>
      {/* Encabezado con Logo y Título */}
      <AppBar position="static" sx={{ marginBottom: 0, bgcolor: '#8c52fe' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="h6">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: '80%', height: '80%', marginTop: '10px', marginBottom: '5px' }}
                  />
                </Typography>
              </Grid>
              <Grid alignItems="left" >
                <Box sx={{ p: 2 }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }} >
                    ID Viaje: {viaje.id}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>

      <Box>
        <Grid container spacing={2} sx={{ height: "100%" }} alignItems="stretch">
          {/* Mapa */}
          <Grid item xs={12} sm={8.5} md={9.5} sx={{ height: "100%" }}>
            <LoadScriptNext googleMapsApiKey="AIzaSyAZJ7jCNaAU30Edy29CrsGGPwSdVLtY_qM">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                mapTypeId="roadmap"
              >
                {polylinePath.length > 0 && (
                  <>
                    <Polyline
                      path={polylinePath}
                      options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 1,
                        strokeWeight: 5,
                      }}
                    />
                    <Marker
                      position={polylinePath[0]}
                      icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                      }}
                    />
                    <Marker
                      position={polylinePath[polylinePath.length - 1]}
                      icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                      }}
                    />
                  </>
                )}
              </GoogleMap>
            </LoadScriptNext>
          </Grid>

          {/* Panel Lateral con Información del Viaje */}
          <Slide direction="right" in={isSectionVisible} mountOnEnter unmountOnExit>
            <Grid item xs={12} sm={3.5} md={2.5} sx={{ height: "100%" }}>
              <Card sx={{ boxShadow: 10, textAlign: "left", backgroundColor: grey[200], height: "100%", display: "flex", flexDirection: "column" }}>
                <Paper square sx={{ flex: 1, overflow: "auto", backgroundColor: grey[200], p: 1 }}>
                  <HoverableCard>
                    <Box sx={{ p: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Conductor: {viaje?.Datos?.usuario || "Desconocido"}
                      </Typography>
                      <Typography variant="body1">
                        Fecha inicio: {viaje?.Datos?.fechaInicio}
                      </Typography>
                      <Typography variant="body1">
                        Hora inicio: {viaje?.Datos?.horaInicio}
                      </Typography>
                      <Typography variant="body1">
                        Fecha término: {viaje?.Datos?.fechaFin || "En curso"}
                      </Typography>
                      <Typography variant="body1">
                        Hora término: {viaje?.Datos?.horaFin || "En curso"}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          color: viaje?.Datos?.fechaFin && viaje?.Datos?.horaFin ? "error.main" : "success.main"
                        }}
                      >
                        Estado: {viaje?.Datos?.fechaFin && viaje?.Datos?.horaFin ? "Terminado" : "En curso"}
                      </Typography>
                    </Box>
                  </HoverableCard>
                </Paper>
              </Card>
            </Grid>
          </Slide>
        </Grid>
      </Box>
    </>
  );
}
