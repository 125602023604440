import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LockOpen } from "@mui/icons-material";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";
import { AuthenticacionProveedor } from "../../APIS/apiProveedor";
import Image from "mui-image";
import Logo from "../Imagenes/Logo_morado__icono_y_texto_abajo.png";
import logoSin from "../Imagenes/Icono_morado.png";

export function Login({ handleLogin }) {
  const paperStyle = {
    padding: "20px",
    width: "80%",
    maxWidth: "400px",
    margin: "auto",
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const btnStyle = {
    marginTop: "20px",
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  // Estado para manejar errores y loading
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  // Si el usuario ya está autenticado en localStorage, redirigimos
  useEffect(() => {
    const auth = localStorage.getItem("isAuthenticated");
    const position = localStorage.getItem("position");

    if (auth === "true") {
      // Dependiendo de la posición, redirigimos
      if (position === "Conductor") {
        navigate("/Conductor");
      } else if (position === "Operador") {
        navigate("/PortalProveedor");
      } else {
        // Caso por defecto si no reconocemos el rol
        navigate("/PortalProveedor");
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Limpiamos error previo

    // Validación simple en el cliente
    if (!username || !password) {
      setErrorMessage("Por favor, complete ambos campos");
      return;
    }

    setLoading(true);

    try {
      // Petición a tu API de autenticación
      const {
        isAuthenticated,
        counter,
        firstname,
        status,
        position,
      } = await AuthenticacionProveedor(username, password);

      if (isAuthenticated && status === "Autorizado") {
        // Marcamos la sesión como válida
        handleLogin(true);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("counter", counter);
        localStorage.setItem("firstname", firstname);
        localStorage.setItem("position", position);

        // Redirigir según posición
        if (position === "Conductor") {
          navigate("/Conductor");
        } else if (position === "Operador") {
          navigate("/PortalProveedor");
        } else {
          // Rol no reconocido
          setErrorMessage("Rol no reconocido. Contacte al administrador.");
          // Podrías hacer un return aquí para no hacer el navigate
        }
      } else if (status === "Bloqueado") {
        setErrorMessage(
          "Este usuario está bloqueado. Por favor, contacte al administrador."
        );
      } else {
        setErrorMessage("Credenciales inválidas");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Hubo un error al autenticar. Inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: `url(${logoSin}) center / 30%  rgba(200, 200, 200, 0.7)`,
      }}
    >
      <Box>
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={24} style={paperStyle}>
            <Grid align="center">
              <Image width="80%" src={Logo} duration={50} />
              <br />
              <Typography variant="h6" display="block">
                Iniciar Sesión
              </Typography>
            </Grid>

            {/* Mensaje de error, si lo hay */}
            {errorMessage && (
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}

            <TextField
              fullWidth
              color="primary"
              margin="normal"
              variant="outlined"
              label="Usuario"
              type="text" // <-- Ajustado a un tipo HTML válido
              name="usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading} // deshabilita si está cargando
            />

            <TextField
              fullWidth
              color="primary"
              margin="normal"
              variant="outlined"
              label="Contraseña"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />

            <Button
              startIcon={<LockOpen />}
              color="primary"
              variant="contained"
              style={btnStyle}
              onClick={handleSubmit}
              fullWidth
              disabled={loading} // deshabilita para evitar múltiples clics
            >
              {/* Muestra spinner o texto según loading */}
              {loading ? <CircularProgress size={24} color="inherit" /> : "INGRESAR"}
            </Button>

            <Typography variant="overline" display="block" sx={{ mt: 2 }}>
              Sistema de transporte Proveedor
            </Typography>
            <Typography variant="overline" color="initial">
              Rhapcore
            </Typography>
          </Paper>
        </Grid>
        <br />
      </Box>
    </div>
  );
}
